//module
import { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";

//Icons
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { BsGithub, BsTwitter } from "react-icons/bs";
import { BiMessage } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";

//Components
import CusModal from "../components/CusModal";

const Social = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {}, [show]);

  const [state, handleSubmit] = useForm("xbjzojpj");
  return (
    <>
      <div id="social">
        <div>
          <FaLinkedinIn
            className="socialIcons"
            onClick={() => window.open("https://www.linkedin.com/in/shadman-chowdhury-69427b123/")}
          />
          <BsInstagram
            className="socialIcons"
            onClick={() => window.open("https://www.instagram.com/shad_k_c/?hl=en")}
          />
          <BiMessage
            style={{
              fontSize: "60px",
              border: "1px solid #AB6E50",
              borderRadius: "100%",
              padding: "10px",
              borderSpacing: "20px",
            }}
            className="socialIcons"
            onClick={() => {
              setShow(true);
            }}
          />
          <BsGithub className="socialIcons" onClick={() => window.open("https://github.com/thatonenerd2000")} />
          <BsTwitter className="socialIcons" onClick={() => window.open("https://twitter.com/_shadman_")} />
        </div>
      </div>
      <CusModal visible={show}>
        <div style={{ width: "100%", textAlign: "right" }}>
          <AiOutlineCloseCircle style={{ color: "red" }} className="socialIcons" onClick={() => setShow(false)} />
        </div>
        <div id="formHolder">
          <form onSubmit={handleSubmit}>
            <input type="text" name="name" placeholder="Name" />
            <input id="email" type="email" name="email" placeholder="email" />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
            <textarea id="message" name="message" placeholder="Message" />
            <ValidationError prefix="Message" field="message" errors={state.errors} />
            <p style={{ display: state.succeeded ? "block" : "none" }}>Thanks for the message!</p>
            <button type="submit" disabled={state.submitting}>
              Submit
            </button>
          </form>
        </div>
      </CusModal>
    </>
  );
};

export default Social;
