//Import styles
import "../style.scss";

const Terminal = (props) => {
  return (
    <div id="terminal" style={{ height: props.height, width: props.width }} className={props.className}>
      <div id="terminal_header">
        <div className="terminal_header_buttons" style={{ backgroundColor: "rgb(255, 90, 82)" }}></div>
        <div className="terminal_header_buttons" style={{ backgroundColor: "rgb(230, 192, 42)" }}></div>
        <div className="terminal_header_buttons" style={{ backgroundColor: "rgb(83, 164, 50)" }}></div>
      </div>
      <div className="terminal_body" id={props.id} style={props.style}>
        {props.children}
      </div>
    </div>
  );
};

export default Terminal;
