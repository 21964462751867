//modules
import { useState, useContext } from "react";

//Context
import { ConfigContext } from "../../GlobalContext";

import { ref, push, set, child } from "firebase/database";

const NewSkill = () => {
  const Globalconfig = useContext(ConfigContext);

  const [skillName, setSkillName] = useState("");
  return (
    <div className="admin_upload">
      <h3>
        <u>New Skill</u>
      </h3>
      <select id="skill_category">
        <option value="programmingSkill">Programming Skills</option>
        <option value="otherSkill">Other Skills</option>
      </select>
      <br></br>
      <input type="text" placeholder="Skill Name" onChange={(e) => setSkillName(e.target.value)} value={skillName} />
      <button
        onClick={() => {
          const data = {
            skill: skillName,
          };
          if (Globalconfig.login) {
            const db = Globalconfig.firebaseDatabase;
            const category = document.getElementById("skill_category").value;
            const newPostKey = push(child(ref(db), `skills/${category}`)).key;
            set(ref(db, `skills/${category}/${newPostKey}`), data);
            setSkillName("");
          }
        }}
      >
        Add Skill
      </button>
    </div>
  );
};

export default NewSkill;
