import React from "react";

//Firebase Imports
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

//Context
export const ConfigContext = React.createContext();

const GlobalContext = (props) => {
  //Firebase
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  // eslint-disable-next-line
  const analytics = getAnalytics(app);

  const FirebaseDatabase = getDatabase();
  const StorageFirebase = getStorage();
  const auth = getAuth();

  const [loggedIn, setLoggedIn] = React.useState(false);

  return (
    <ConfigContext.Provider
      value={{
        firebaseDatabase: FirebaseDatabase,
        firebaseStorage: StorageFirebase,
        firebaseAuth: auth,
        login: loggedIn,
        setLoggedIn,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default GlobalContext;
