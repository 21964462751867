//modules
import { useState, useContext } from "react";

//Context
import { ConfigContext } from "../../GlobalContext";

import { ref, push, set, child } from "firebase/database";

const UploadProject = () => {
  const Globalconfig = useContext(ConfigContext);

  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectUrl, setProjectUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [addLang, setAddLang] = useState("");
  const [language, setLanguage] = useState([]);
  return (
    <div className="admin_upload">
      <h3>
        <u>Upload Project:</u>
      </h3>
      <select id="project_category">
        <option value="published">Published</option>
        <option value="schoolProjects">School Projects</option>
      </select>
      <br></br>
      <input
        type="text"
        placeholder="Project Name"
        onChange={(e) => setProjectName(e.target.value)}
        value={projectName}
      />
      <br></br>
      <textarea
        type="text"
        placeholder="Project Description"
        onChange={(e) => setProjectDescription(e.target.value)}
        value={projectDescription}
      />
      <br></br>
      <input type="text" placeholder="Project URL" onChange={(e) => setProjectUrl(e.target.value)} value={projectUrl} />
      <br></br>
      <input type="text" placeholder="Preview URL" onChange={(e) => setPreviewUrl(e.target.value)} value={previewUrl} />
      <br></br>
      <input
        type="text"
        placeholder="Language"
        id="lang"
        onChange={(e) => setAddLang(e.target.value)}
        value={addLang}
      />
      <button
        onClick={() => {
          setLanguage([...language, addLang]);
          setAddLang("");
        }}
      >
        Add Language
      </button>
      <br></br>
      <p>
        Languages:
        {language.map((lang) => {
          return <span>{lang}, </span>;
        })}
      </p>
      <button
        onClick={() => {
          const category = document.getElementById("project_category").value;
          const data = {
            Category: category,
            Description: projectDescription,
            Name: projectName,
            Github: projectUrl,
            Preview: previewUrl,
            Language: language,
          };
          const db = Globalconfig.firebaseDatabase;
          const newPostKey = push(child(ref(db), `projects/${category}`)).key;
          set(ref(db, `projects/${category}/${newPostKey}`), data);
          setProjectName("");
          setProjectDescription("");
          setProjectUrl("");
          setPreviewUrl("");
          setLanguage([]);
        }}
      >
        Upload
      </button>
    </div>
  );
};

export default UploadProject;
