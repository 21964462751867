//Modules
import { useEffect, useContext, useState } from "react";

//logo
import logo from "../media/logo.png";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Firebase Methods
import { getData } from "../function/firebaseMethods.js";

//Context
import { ConfigContext } from "../GlobalContext";

//Modules
import { isMobile } from "react-device-detect";

const About = () => {
  const Globalconfig = useContext(ConfigContext);

  const [programs, setPrograms] = useState([]);
  const [programsKeys, setProgramsKeys] = useState([]);

  const [shows, setShows] = useState([]);
  const [showsKeys, setShowsKeys] = useState([]);

  const [work, setWork] = useState([]);
  const [workKeys, setWorkKeys] = useState([]);

  useEffect(() => {
    getData(Globalconfig.firebaseDatabase, "Programs/").then((retData) => {
      setPrograms(retData);
      setProgramsKeys(Object.keys(retData).sort((a, b) => 0.5 - Math.random()));
    });
    getData(Globalconfig.firebaseDatabase, "AboutOutside/Sponsership/").then((retData) => {
      setShows(retData);
      setShowsKeys(Object.keys(retData).sort((a, b) => 0.5 - Math.random()));
    });
    getData(Globalconfig.firebaseDatabase, "WorkExperience/").then((retData) => {
      setWork(retData);
      setWorkKeys(Object.keys(retData).reverse());
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div id="aboutContainer">
      <img id="abtLogo" src={logo} alt="logo" style={{ height: "25vh" }} />
      <h1>Hey there, it's Shad here</h1>
      <h4>A little about me</h4>
      <span style={{ display: isMobile ? "block" : "none" }}>
        <h2>&darr;</h2>
      </span>
      <hr />
      <Row>
        <Col sm={6} style={{ textAlign: "left" }}>
          <strong>
            <h3>About me</h3>
            <p>
              I am a programmer, scholar, <a href="https://www.twitch.tv/thatonenerd2000">gamer</a>,{" "}
              <a href="https://www.tofugu.com/japan/otaku-meaning/">otaku</a> and other stuff that I still haven't
              figured out yet. My journey in the USA began in 2015, I am made in{" "}
              <a href="https://en.wikipedia.org/wiki/Bangladesh">
                <span id="bd">bangladesh</span>
              </a>
              . Currently pursuing Bachelors of Science degree in{" "}
              <a href="https://en.wikipedia.org/wiki/Computer_science">Computer Science</a> @{" "}
              <a style={{ color: "#005bbb" }} href="https://www.buffalo.edu/">
                University at Buffalo
              </a>
              ...Go Bulls. Love to explore tech and always eager to learn more about it.
            </p>
            <br />
            <h3>Programs / Hackathons / Mentorship</h3>
            {programsKeys.map((key) => {
              return (
                <img
                  style={{ filter: isMobile ? "grayscale(0)" : null }}
                  className="programImg"
                  src={programs[key].imgUrl}
                  alt={programs[key].ProgramName}
                  key={key}
                />
              );
            })}
            <br />
            <br />
            <h3>Sponserships</h3>
            <p>Outside of writing scripts all day long, I love being a proud sponsor of the followings and more :)</p>
            {showsKeys.map((key) => {
              return (
                <img
                  style={{ filter: isMobile ? "grayscale(0)" : null }}
                  className="programImg"
                  src={shows[key].imgUrl}
                  alt={shows[key].ProgramName}
                  key={key}
                />
              );
            })}
            <br />
          </strong>
        </Col>
        <Col style={{ textAlign: "left" }} sm={6}>
          <h3>Work Experience</h3>
          {workKeys.map((key) => {
            return (
              <div className="workExperience" key={key}>
                <a href={work[key].WorkLink}>
                  <h2>{work[key].WorksiteName}</h2>
                </a>
                <strong>
                  <h4>{work[key].Workposition}</h4>
                </strong>
                <p>
                  {work[key].WorkedFrom} - {work[key].WorkedTo}
                </p>
                <p>{work[key].WorkDescription}</p>
              </div>
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default About;
