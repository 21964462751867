import { useEffect, useContext, useState } from "react";

//Context
import { ConfigContext } from "../GlobalContext";

//Firebase Methods
import { getData } from "../function/firebaseMethods.js";

//Styles
import "../style.scss";

//Media
import logo from "../media/logo.png";

//Router
import { useNavigate } from "react-router-dom";

const Skills = () => {
  const Globalconfig = useContext(ConfigContext);

  const [programmingSkills, setProgrammingSkills] = useState([]);
  const [programmingSkillsKeys, setProgrammingSkillsKeys] = useState([]);
  const [otherSkills, setOtherSkills] = useState([]);
  const [otherSkillsKeys, setOtherSkillsKeys] = useState([]);

  useEffect(() => {
    getData(Globalconfig.firebaseDatabase, "/skills/").then((retData) => {
      setProgrammingSkills(retData.programmingSkill);
      setProgrammingSkillsKeys(Object.keys(retData.programmingSkill).sort((a, b) => 0.5 - Math.random()));
      setOtherSkills(retData.otherSkill);
      setOtherSkillsKeys(Object.keys(retData.otherSkill).sort((a, b) => 0.5 - Math.random()));
    });
    // eslint-disable-next-line
  }, []);

  let navigate = useNavigate();

  return (
    <>
      <div id="skillsHolder">
        <img
          src={logo}
          alt="logo"
          className="logo"
          style={{ border: "0px", height: "20vh", cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <hr style={{ border: "1px solid #0f0" }}></hr>
        <div style={{ textAlign: "left" }}>
          <p style={{ border: "0px" }}>programming languages:</p>
          <br></br>
          {programmingSkillsKeys.map((key) => {
            return <p key={key}>{programmingSkills[key].skill}</p>;
          })}
          <br></br>
          <br></br>
          <p style={{ border: "0px" }}>other skills:</p>
          <br></br>
          {otherSkillsKeys.map((key) => {
            return <p key={key}>{otherSkills[key].skill}</p>;
          })}
        </div>
      </div>
    </>
  );
};

export default Skills;
