import { useEffect, useContext, useState } from "react";

//Context
import { ConfigContext } from "../GlobalContext";

//Firebase Methods
import { getData } from "../function/firebaseMethods.js";

//Modules
import { isMobile } from "react-device-detect";

//Styles
import "../style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//Components
import Skills from "../helper/Skills";
import Projects from "../helper/Projects";

const ProjectsAndSkills = () => {
  const Globalconfig = useContext(ConfigContext);

  const [publishedProjects, setPublishedProjects] = useState([]);
  const [publishedProjectsKeys, setPublishedProjectsKeys] = useState([]);

  const [collegeAndHighSchoolProjects, setCollegeAndHighSchoolProjects] = useState([]);
  const [collegeAndHighSchoolProjectsKeys, setCollegeAndHighSchoolProjectsKeys] = useState([]);

  useEffect(() => {
    getData(Globalconfig.firebaseDatabase, "/projects/").then((retData) => {
      setPublishedProjects(retData.published);
      setPublishedProjectsKeys(Object.keys(retData.published).sort((a, b) => 0.5 - Math.random()));
      setCollegeAndHighSchoolProjects(retData.schoolProjects);
      setCollegeAndHighSchoolProjectsKeys(Object.keys(retData.schoolProjects));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div id="projects_and_skills">
        <Row id="project_and_skills_row">
          <Col className="Cols" sm={3}>
            <Skills />
          </Col>
          <Col className="Cols" sm={9}>
            <div id="projectsHolder" style={isMobile ? { display: "flex", justifyContent: "center" } : null}>
              <h4 style={{ width: "100%", textAlign: "center" }}>
                <u>Projects</u>
              </h4>
              {publishedProjectsKeys.map((key) => {
                return (
                  <Projects
                    key={key}
                    name={publishedProjects[key].Name}
                    description={publishedProjects[key].Description}
                    language={publishedProjects[key].Language}
                    github={publishedProjects[key].Github}
                    preview={publishedProjects[key].Preview}
                  />
                );
              })}
              {collegeAndHighSchoolProjectsKeys.map((key) => {
                return (
                  <Projects
                    key={key}
                    name={collegeAndHighSchoolProjects[key].Name}
                    description={collegeAndHighSchoolProjects[key].Description}
                    language={collegeAndHighSchoolProjects[key].Language}
                    github={collegeAndHighSchoolProjects[key].Github}
                    preview={collegeAndHighSchoolProjects[key].Preview}
                  />
                );
              })}
              <br></br>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProjectsAndSkills;
