//Icons
import { SiFirebase, SiTypescript } from "react-icons/si";
import { DiJavascript1, DiNodejsSmall } from "react-icons/di";
import { FaJava, FaPython, FaSass, FaReact, FaSwift } from "react-icons/fa";
import { FcAndroidOs } from "react-icons/fc";

const ProjectIcons = (props) => {
  switch (props.name) {
    case "react":
      return <FaReact style={{ color: "#61DBFB" }} />;
    case "firebase":
      return <SiFirebase style={{ color: "#F5A623" }} />;
    case "sass":
      return <FaSass style={{ color: "#c69" }} />;
    case "javascript":
      return <DiJavascript1 style={{ color: "yellow" }} />;
    case "node":
      return <DiNodejsSmall style={{ color: "#215732" }} />;
    case "java":
      return <FaJava style={{ color: "#5382a1" }} />;
    case "python":
      return <FaPython style={{ color: "#FFE873" }} />;
    case "android":
      return <FcAndroidOs style={{ color: "#A4C639" }} />;
    case "typescript":
      return <SiTypescript style={{ color: "#2B7489" }} />;
    case "swift":
      return <FaSwift style={{ color: "#ff5722" }} />;
    default:
      return null;
  }
};

export default ProjectIcons;
