//module
import { useEffect, useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";

//Context
import { ConfigContext } from "../../GlobalContext";

const Login = () => {
  const Globalconfig = useContext(ConfigContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <input type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} />
      <input type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} />
      <button
        onClick={() => {
          const auth = Globalconfig.firebaseAuth;
          signInWithEmailAndPassword(auth, email, password)
            .then((usr) => {
              //signed in
              Globalconfig.setLoggedIn(true);
            })
            .catch((err) => {
              //error
              alert(err);
            });
        }}
      >
        Login
      </button>
    </>
  );
};

export default Login;
