const CusModal = (props) => {
  return (
    <>
      <div style={{ display: props.visible ? "block" : "none" }} id="modalOverlay"></div>
      <div className="CusModal">
        <div style={{ display: props.visible ? "block" : "none" }} className="CusModal-content">
          {props.children}
        </div>
      </div>
    </>
  );
};

export default CusModal;
