//Import styles
import "../style.scss";
import ParticleBackground from "react-particle-backgrounds";

//Router
import { useNavigate } from "react-router-dom";

//Media
import logo from "../media/logo.png";

//Components
import Terminal from "../components/Terminal";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

const Landing = () => {
  const PartcleSettings = {
    canvas: {
      canvasFillSpace: true,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 100,
      color: "#0f0",
      minSize: 1,
      maxSize: 4,
    },
    velocity: {
      minSpeed: 0.2,
      maxSpeed: 0.4,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.6,
      opacityTransitionTime: 10000,
    },
  };

  let navigate = useNavigate();

  return (
    <>
      <div style={{ height: "100vh", width: "100vw", position: "absolute" }}>
        <ParticleBackground settings={PartcleSettings} />
      </div>
      <div id="landing">
        <Terminal id="landing_terminal" height={"80vh"} width={"85vw"}>
          <img src={logo} alt="logo" className="logo" />
          <br></br>
          <p>
            Hey there, its <strong>Shad</strong> here...<span id="cursorAnim">|</span>
          </p>
          <div id="terminal_button_container">
            <Button
              className="terminal_button"
              onClick={() => {
                navigate("/about");
              }}
            >
              About
            </Button>
            <Button
              className="terminal_button"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact
            </Button>
            <Button
              className="terminal_button"
              onClick={() => {
                navigate("/projects-and-skills");
              }}
            >
              Projects & Skills
            </Button>
          </div>
        </Terminal>
      </div>
    </>
  );
};

export default Landing;
