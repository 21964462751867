//Components
import Social from "../helper/Social";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Contact = () => {
  return (
    <div id="contact">
      <div id="contactContainer">
        <h1 id="contactWelcome">
          Let's
          <br />
          Get In
          <br />
          Touch
        </h1>
        <p>Got any question or need general information? Feel free to reach out.</p>
        {/* <hr style={{ border: "1px solid white", width: "50%", margin: "auto" }} /> */}
        <br />
        <Row id="contactRow">
          <Col className="Cols" sm={6} style={{ flexDirection: "column" }}>
            <h2>Email</h2>
            <br />
            <a href="mailto:shadman.kc@gmail.com">shadman.kc@gmail.com</a>
          </Col>
          <Col className="Cols" sm={6} style={{ flexDirection: "column" }}>
            <h2>Phone</h2>
            <br />
            <p>347-493-9265</p>
          </Col>
        </Row>
        <hr style={{ border: "1px solid white", width: "40%", margin: "auto" }} />
        <br />
        <Social />
      </div>
    </div>
  );
};

export default Contact;
