//Modules
import { isMobile } from "react-device-detect";

//Components
import Terminal from "../components/Terminal";
import ProjectIcons from "./ProjectIcons";

//Styles
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

const Projects = (props) => {
  return (
    <>
      <Terminal className="projects_terminal" height="fit-content" width={isMobile ? "98vw" : "48%"}>
        <div id="projectName">
          <h2>{props.name}</h2>
          <hr style={{ border: "2px solid white" }}></hr>
        </div>
        <div id="projectDescription">
          <p>{props.description}</p>
          <Button
            className="terminal_button"
            style={{ marginRight: 1, marginLeft: 0, backgroundColor: props.github === "" ? "grey" : null }}
            disabled={props.github === "" ? true : false}
            onClick={() => {
              window.open(props.github, "_blank");
            }}
          >
            {props.github === "" ? "Private Source" : "View Source"}
          </Button>
          <Button
            className="terminal_button"
            style={{ marginRight: 1, marginLeft: 0 }}
            disabled={props.preview === "" ? true : false}
            onClick={() => {
              console.log(props.preview);
              window.open(props.preview, "_blank");
            }}
          >
            {props.preview === "" ? "Private Preview" : "View Preview"}
          </Button>
          <br></br>
          <div style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
            {props.language.map((item) => {
              return <ProjectIcons key={item} name={item} />;
            })}
          </div>
        </div>
      </Terminal>
    </>
  );
};

export default Projects;
