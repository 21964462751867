//module
import { useState } from "react";

//icons
import { HiOutlineMenu } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { BiTerminal } from "react-icons/bi";
import { IoMdContact } from "react-icons/io";
import { AiOutlineHome } from "react-icons/ai";

//Router
import { useNavigate } from "react-router-dom";

const Menu = () => {
  const [toggle, setToggle] = useState(true);

  let navigate = useNavigate();

  return (
    <div id="menuContainer">
      <div
        className="menuItem"
        style={{ backgroundColor: "#fff", display: toggle ? "block" : "none" }}
        onClick={() => navigate("/")}
      >
        <AiOutlineHome style={{ color: "black" }} className="menuIcon" />
      </div>
      <div
        className="menuItem"
        style={{ backgroundColor: "#473A2E", display: toggle ? "block" : "none" }}
        onClick={() => navigate("/about")}
      >
        <IoMdContact className="menuIcon" />
      </div>
      <div
        className="menuItem"
        style={{ backgroundColor: "#4D4141", display: toggle ? "block" : "none" }}
        onClick={() => navigate("/projects-and-skills")}
      >
        <BiTerminal className="menuIcon" />
      </div>
      <div
        className="menuItem"
        style={{ backgroundColor: "#3F2D27", display: toggle ? "block" : "none" }}
        onClick={() => navigate("/contact")}
      >
        <MdEmail className="menuIcon" />
      </div>
      <div
        id="menuBar"
        className="menuItem"
        style={{ backgroundColor: "#462A28", transform: toggle ? "rotate(90deg)" : "rotate(0deg)" }}
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        <HiOutlineMenu className="menuIcon" />
      </div>
    </div>
  );
};

export default Menu;
