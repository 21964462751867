import React from "react";

//Global Context
import GlobalContext from "./GlobalContext";

//Router
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Components
import Menu from "./components/Menu";

//Views
import Landing from "./views/Landing";
import ProjectsAndSkills from "./views/ProjectsAndSkills";
import About from "./views/About";
import Contact from "./views/Contact";
import Admin from "./admin/Admin";

const App = () => {
  return (
    <>
      <GlobalContext>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Landing />} />
            <Route path="/projects-and-skills" element={<ProjectsAndSkills />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/a8min" element={<Admin />} />
          </Routes>
          <Menu />
        </BrowserRouter>
      </GlobalContext>
    </>
  );
};

export default App;
