//Components
import Login from "./components/Login";
import UploadProject from "./components/UploadProject";
import NewSkill from "./components/NewSkill";

//module
import { useEffect, useContext } from "react";

//Context
import { ConfigContext } from "../GlobalContext";

const Admin = () => {
  const Globalconfig = useContext(ConfigContext);

  useEffect(() => {}, [Globalconfig.login]);

  if (Globalconfig.login) {
    return (
      <>
        <UploadProject />
        <NewSkill />
      </>
    );
  } else {
    return <Login />;
  }
};

export default Admin;
